@media all and (min-width: 480px) {
    .URAC {
        padding: 15px 0;
    }

    .URAC form {
        margin: 0 auto;
        max-width: 420px;
    }
}

.thirdPartyLogin {
    background-color: #f5f5f5;
}

@media all and (max-width: 480px) {
    .URAC {
        padding: 0 15px;
    }
}